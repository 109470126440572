import React from 'react'
import { Button, Col, Row} from 'react-bootstrap'
import GameInformation from './GameInformation'
import GameRewardList from './GameRewardList'
import trophy from '../Web/img/trophy.png'
import { useNavigate } from 'react-router-dom'

export default function GameCard({ticketnum}) {
    const navigate = useNavigate();
  return (
            <Col md={6} className='secondary-color'>
                <Row className='ticket-head ticket-head-green-yellow'>
                    <Col md={10} sm={10} xs={10}><p className='mb-0'>{ticketnum}</p></Col>
                    <Col md={2} sm={2} xs={2}>
                        <p className='mb-0 text-right ticket-name'>
                            <GameInformation/>
                        </p>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col md={6} xs={6} sm={6}>
                        <div>
                           <h4 className='d-flex align-items-center'><img className='w-35px' src={trophy} alt="Tambola Winners"/> <b>Rs. 23455</b></h4>
                           <h6>Jackpot: 500/-</h6>
                        </div>
                    </Col>
                    <Col md={6} xs={6} sm={6}>
                        <div className='float-right'>
                            <p className='mb-0 text-align-end'>Price: <b>20/-</b></p>
                            <Button onClick={() => navigate(`/`)} className='float-right px-3 py-0' variant='danger'>Completed</Button>
                        </div>
                    </Col>
                </Row>
                <Row className='mb-2 align-items-end'>
                    <Col md={6} xs={6} sm={6}>
                       <GameRewardList/>
                    </Col>
                    <Col md={6} xs={6} sm={6}>
                        <p className='float-right'><b>12:00 PM 18 Jan</b></p>
                    </Col>
                </Row>
            </Col>
        )
    }
