import React from 'react'
import { Col, Row} from 'react-bootstrap'

export default function Board() {
  const numbers = Array.from({ length: 90 }, (_, index) => index + 1);
  return (
            <Col md={6} className='secondary-color board-spacing'>
                <Row className='justify-content-center'>
                    {
                        numbers.map((number, index) => <div key={index} className='board-num'><span>{number}</span></div>)
                    }
                </Row>
            </Col>
        )
    }
