import React from 'react'
import { Col, Row} from 'react-bootstrap'
import calander from '../Web/img/calander.png'
import clock from '../Web/img/clock.png'
import { Link } from 'react-router-dom'

export default function TimerCard() {
  return (
            <Col md={6} className='secondary-color'>
                <Row className='mb-0 pt-3 align-center mbl-text'>
                    <Col md={4} sm={4} xs={4}>
                        <h5 className='mb-0'><img src={calander} alt="calander"/> 10 Jan 2024</h5>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <h5 className='mb-0 text-center'>05:20:28</h5>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <h5 className='mb-0 text-right ticket-name'><img src={clock} alt="clock"/> 10:00 PM</h5>
                    </Col>
                </Row>
                <Row className='mb-0 pt-1 align-center mbl-text'>
                    <Col md={4} sm={4} xs={4} className='px-1'>
                        <Link to={`/game`} className='text-decoration-none'><p className='mb-0 text-center mt-1 base-color tabs-radius text-white'><span>Live</span><i className="bi bi-arrow-right-short"></i></p></Link>
                    </Col>
                    <Col md={4} sm={4} xs={4} className='px-1'>
                        <Link to={`/agent-game-list`} className='text-decoration-none'><p className='mb-0 text-center mt-1 base-color tabs-radius text-white'><span>Upcoming</span><i className="bi bi-arrow-right-short"></i></p></Link>
                    </Col>
                    <Col md={4} sm={4} xs={4} className='px-1'>
                        <Link to={`/agent-completed-game`} className='text-decoration-none'><p className='mb-0 text-center mt-1 base-color tabs-radius text-white'><span>Completed</span><i className="bi bi-arrow-right-short"></i></p></Link>
                    </Col>
                </Row>
            </Col>
        )
    }
