import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getAgents, getSubAdmin, getUsers, resetLockedMoney, restRecord } from "../../reducers/commonReducer";

const schema = yup.object().shape({

});

function AmountAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const subadminId = queryParameters.get('subadminId');
  const {user_id, lockedmoney, trans_coins, api, type, username, lockedmoneyAmount, coins} = props;
  return (
    <>
      <Button variant={type === "Add" ? "success" : 'danger'} className="m-1 py-1 px-2 box-shadow" onClick={handleShow}>
       {type === "Add" ? <i className="bi bi-plus-circle"></i> : <i className="bi bi-dash-circle"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>{`${type} Amount (${username})`}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResult = await dispatch(api(values))
            if(apiResult?.payload?.status === 1){
              dispatch(restRecord())
              resetForm({ values: "" });
              if ((localStorage.getItem("userRole") === "1") && subadminId) {
                dispatch(getUsers())
              } else if (localStorage.getItem("userRole") === "1") {
                dispatch(getSubAdmin())
              } else if (((localStorage.getItem("userRole") === "2") && localStorage.getItem("website")?.length > 0)){
                dispatch(getAgents())
              } else {
                dispatch(getUsers())
              }
              setShow(false)
            }
          }}
          initialValues={{
            user_id, lockedmoney, trans_coins,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={type === "Add" ? 6 : 12}>
                  <Form.Group >
                    <Form.Label>Enter Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Amount"
                      name="trans_coins"
                      value={values.trans_coins}
                      onChange={(e) => {
                        setFieldValue('trans_coins', type === "Add" ? +Math.abs(e.target.value) : -Math.abs(e.target.value));
                      }}
                      isInvalid={!!errors.trans_coins}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.trans_coins}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {
                  type === "Add" && <Col md={6}>
                  <Form.Group >
                    <Form.Label>Enter Cashback</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Cashback"
                      name="lockedmoney"
                      value={values.lockedmoney}
                      onChange={handleChange}
                      isInvalid={!!errors.lockedmoney}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lockedmoney}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                }
              </Row>
              {
                type !== "Add" && <Row className="mb-3">
                <Col md={8}>
                  <h5><b>Max Withdrawal Amount Limit <span className='text-danger'>{coins?.toFixed(2) - lockedmoneyAmount?.toFixed(2)} Rs.</span></b></h5>
                </Col>
                <Col md={4}>
                  <Button variant="danger" className="py-1 px-0"
                  onClick={async() => {
                    await dispatch(resetLockedMoney({
                      user_id
                    }))
                  }}>Reset Locked Amount</Button>
                </Col>
              </Row>
              }
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default AmountAction;
