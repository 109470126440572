import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import { addSubadmin, getSubAdmin, logOutUser, loginbyother, restRecord, updateUserDetails } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import SubAdminAction from "./SubAdminAction";
import AutoFillDate from "../../AutoFillDate";
import moment from "moment";

const SubAdmins = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00'));
  const [endDate, setendDate] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [userStatus, setUserStatus] = useState();
  const [day, setDay] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [searchusername, setSearchusername] = useState();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(getSubAdmin({end:endDate, start:startDate,}));
  }, [dispatch, navigate]);
  const handleSubmit = (e) => {
    dispatch(restRecord())
    dispatch(getSubAdmin({
       end:endDate, start:startDate, userStatus, day, searchusername
    }))
  }
  const { getSubAdminList, subadminBlank } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Sub Admin</h3>
          </Col>
          <Col md={6}>
            <SubAdminAction type={"Add"} buttonFloat={"float-right"} btnTitle={"Add"} api={addSubadmin}/>
          </Col>
        </Row>
        <hr className="m-0"/>
        <Row className="mb-2 user-table">
          <Col md={2}>
            <label>
              <b>Username</b>
            </label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              step="1"
              value={searchusername}
              onChange={(e) => setSearchusername(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>Start Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)
              }
            />
          </Col>
          <Col md={2}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <AutoFillDate setendDate={setendDate} setstartDate={setstartDate} day={day} setDay={setDay}/>
          </Col>
          <Col md={2}>
            <label>
                <b>Select User Status</b>
              </label>
              <Form.Select value={userStatus} onChange={(e)=> setUserStatus(e.target.value)}>
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
              </Form.Select>
          </Col>
          <Col md={2}>
            <Button className="mt-4 w-50" variant="success" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="mt-4 w-50" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
              Clear
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>UserName</th>
                <th>Type</th>
                {/* <th>Total User</th>
                <th>Total Games</th> */}
                {/* <th>Games</th> */}
                <th>Profit/Loss</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!!getSubAdminList &&
                getSubAdminList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className={data?.prime ? 'text-white bg-success' : ''}>{index + 1}.</td>
                      <td className={data?.prime ? "bg-success" : ''}>
                        {
                          <Button variant="success" onClick={async() => {
                           const apiResult = await dispatch(loginbyother({userid : data.id}))
                           if(apiResult?.payload?.status === 1){
                            navigate(`/users`)
                           }
                          }} className="cursor-pointer">{data?.username} ({data?.name})</Button>
                        }
                        {/* <Profile
                            coins={data?.coins.toFixed(2)}
                            username={data?.username}
                            id={data?.id}
                            createdAt={data?.createdAt}
                            email={data?.email}
                            personName={data?.name}
                            phone={data?.phone}
                            role={data?.role?.name}
                            winLoss={(data?.winning - data?.betting_points)?.toFixed(2)}
                            appversion={data?.appversion}
                            device_id={data?.device_id}
                            onlineAmount={data?.cashflow?.online_add_coins}
                            withdraw={data?.cashflow?.withdraw}
                            bonus_coins={data?.cashflow?.bonus_coins}
                            os={data?.os}
                            offline_add_coins={data?.cashflow?.offline_add_coins}
                            customerIp={data?.customerIp}
                            deviceName={data?.deviceName}
                            prime={data?.prime}
                          /> */}
                          <p className="mb-0"><b>ExpireDate:</b> {moment(data?.subscriptionExpire).format('DD-MM-YY')}</p>
                      </td>
                      <td className={data?.appSlug ? `bg-info` : `bg-warning`}>
                        {data?.appSlug ? data?.appSlug : data?.website}
                      </td>
                      {/* <td>
                        20?
                      </td>
                      <td>
                        10 ?
                      </td> */}
                      {/* <td>
                        Number Pd, Live Tambola???
                        {(((new Date() - new Date(data?.updatedAt)) / 1000) > 300) ? <b className="text-danger">OFFLINE</b> : <b className="text-success">ONLINE</b>}
                      </td> */}
                      <td>{(data?.winning - data?.betting_points)?.toFixed(1)}</td>
                      <td className={data.status ? "text-success" : 'text-danger'}>
                        <b>{data.status ? "Active" : "Inactive"}</b>
                      </td>
                      <td>
                        {/* <Profile
                          profileView={true}
                          coins={data?.coins.toFixed(2)}
                          username={data?.username}
                          id={data?.id}
                          createdAt={data?.createdAt}
                          email={data?.email}
                          personName={data?.name}
                          phone={data?.phone}
                          role={data?.role?.name}
                          winLoss={(data?.winning - data?.betting_points)?.toFixed(2)}
                          appversion={data?.appversion}
                          device_id={data?.device_id}
                          onlineAmount={data?.cashflow?.online_add_coins}
                          withdraw={data?.cashflow?.withdraw}
                          bonus_coins={data?.cashflow?.bonus_coins}
                          os={data?.os}
                          offline_add_coins={data?.cashflow?.offline_add_coins}
                          customerIp={data?.customerIp}
                          deviceName={data?.deviceName}
                          prime={data?.prime}
                        /> */}
                        <SubAdminAction
                          type={"Update"}
                          id={data?.id}
                          api={updateUserDetails}
                          status={data?.status}
                          expire={moment(data?.subscriptionExpire).format('YYYY-MM-DD')}
                          games={data?.games}
                          appSlug={data?.appSlug}
                          website={data?.website}
                          password={data?.password}
                          name={data?.name}/>
                        {
                          data?.username !== 'admin' && <Button className="m-1 py-1 px-2" onClick={() =>
                            dispatch(logOutUser({
                              userId : data?.id
                            }))
                          } variant="danger"><i className="bi bi-power"></i></Button>
                        }
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!subadminBlank && subadminBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getSubAdmin({currentPage:currentPage + 1}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default SubAdmins;
