import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getSubAdmin, restRecord } from "../../reducers/commonReducer";
import moment from "moment";

const schema = yup.object().shape({
  name: yup.string().required("Please Enter Name."),
  status: yup.boolean().required("Please Select Status."),
  password: yup.string().when('type', {
    is: (val) => val === 'Add',
    then: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    otherwise: yup.string() 
  }),
  website: yup.string().test('at-least-one-required', 'At least one of Website or App Slug is required.', function(value) {
    const appSlug = this.parent.appSlug || '';
    return (value && value.trim() !== '') || (appSlug && appSlug.trim() !== '');
  }),
  appSlug: yup.string().test('at-least-one-required', 'At least one of Website or App Slug is required.', function(value) {
    const website = this.parent.website || '';
    return (value && value.trim() !== '') || (website && website.trim() !== '');
  }),
  games: yup.array()
  .min(1, 'At least one game is required')
  .of(yup.string()),
  expire: yup.date()
    .when('website', {
      is: website => website && website.trim().length > 0,
      then: yup.date().required('Expire Date is required').min(new Date(), 'Date must be in the future'),
      otherwise: yup.date().min(new Date(), 'Date must be in the future')
    }),
});

function SubAdminAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {btnTitle, api, id, name, password, expire, type, games, appSlug, website, buttonFloat, status} = props;

  const thirtyDays = moment().add(31, 'days').calendar();
  const thirtyDaysFormat = moment(thirtyDays).format('YYYY-MM-DD');
  const quarterFormat = moment().add(3, 'months').format('YYYY-MM-DD');
  const sixMonthFormat = moment().add(6, 'months').format('YYYY-MM-DD');
  const oneYearFormat = moment().add(1, 'year').format('YYYY-MM-DD');
  const startDate = moment();
  return (
    <>
      <Button variant="success" className={`px-2 py-1 ${buttonFloat}`} onClick={handleShow}>
       {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : `${name}`}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(api(values));
            resetForm({ values: "" });
            dispatch(restRecord())
            dispatch(getSubAdmin())
            setShow(false)
          }}
          initialValues={{
            id, name, password, expire, games : games ? games : [], appSlug, website, status, type
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={5}>
                  <Form.Group >
                    <Form.Label>Website Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="abc.com"
                      name="website"
                      value={values.website}
                      onChange={handleChange}
                      isInvalid={!!errors.website}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.website}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <h4 className="mt-4 text-danger text-center mb-0"><b>OR</b></h4>
                </Col>
                <Col md={5}>
                  <Form.Group >
                    <Form.Label>App Slug</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter App Slug"
                      name="appSlug"
                      value={values.appSlug}
                      onChange={handleChange}
                      isInvalid={!!errors.appSlug}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.appSlug}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                    <Form.Group >
                      <Form.Label>Select Days</Form.Label>
                      <Form.Control
                              as="select"
                              onChange={handleChange}
                              name="expire"
                              className="form-control"
                              value={values.expire}
                              isInvalid={!!errors.expire}
                            >
                              <option value="">Select Days</option>
                              <option value={thirtyDaysFormat}>30 Days</option>
                              <option value={quarterFormat}>Quarterly</option>
                              <option value={sixMonthFormat}>6 Months</option>
                              <option value={oneYearFormat}>1 Year</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.expire}
                            </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group >
                      <Form.Label>Expire Date</Form.Label>
                      <Form.Control
                        // type="datetime-local"
                        type="date"
                        placeholder="Enter Date"
                        name="expire"
                        value={values.expire}
                        onChange={handleChange}
                        isInvalid={!!errors.expire}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.expire}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
              </Row>
              <Row className="mb-0">
                <Col className="text-center">
                  <h4><b>{!!values.expire && `${moment(values?.expire).diff(startDate, 'days')} days`}</b></h4>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Select Games</Form.Label>
                      <Form.Control
                              as="select"
                              multiple
                              onChange={handleChange}
                              name="games"
                              className="form-control"
                              value={values.games}
                              isInvalid={!!errors.games}
                            >
                              <option value={"64410e6ed3a7eca8ad628ff3"}>Live Video Tambola</option>
                              <option value={"65a8ff2c80ed7f4e8076f9d4"}>Tambola Automatic</option>
                              {
                                values?.appSlug && <option value={"651f9c4ea5e67037d308e120"}>Number Prediction</option>
                              }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.games}
                            </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Select Status</Form.Label>
                      <Form.Control
                              as="select"
                              onChange={handleChange}
                              name="status"
                              className="form-control"
                              value={values.status}
                              isInvalid={!!errors.status}
                            >
                              <option value="">Select status</option>
                              <option value={true}>Active</option>
                              <option value={false}>InActive</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default SubAdminAction;
