import React from 'react'
import { Form } from "react-bootstrap";
import moment from 'moment';

export default function AutoFillDate({setendDate, setDay, day, setstartDate}) {
    const autoFillDate = (e) => {
        let daysData = e.target.value;
        if (daysData === 'today') {
          setDay(e.target.value);
          setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
          setstartDate(moment().format('YYYY-MM-DDT00:00:00'));
        }
        if (daysData === 'yesterday') {
          setDay(e.target.value);
          setendDate(moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DDT23:59:59'));
          setstartDate(moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'));
        }
        if (daysData === 'thisWeek') {
          setDay(e.target.value);
          setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
          setstartDate(moment().startOf('week').add('d',1).format('YYYY-MM-DDT00:00:00'));
        }
        if (daysData === 'lastWeek') {
          setDay(e.target.value);
          setendDate(moment().subtract(1, 'weeks').endOf('week').add('d',1).format('YYYY-MM-DDTHH:mm:ss'));
          setstartDate(moment().subtract(1, 'weeks').startOf('week').add('d',1).format('YYYY-MM-DDT00:00:00'));
        }
        if (daysData === 'thisMonth') {
          setDay(e.target.value);
          setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
          setstartDate(moment().startOf('month').format('YYYY-MM-DDT00:00:00'));
        }
        if (daysData === 'thisLastMonth') {
          setDay(e.target.value);
          setendDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
          setstartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DDT00:00:00'));
        }
      };
  return (
   <>
            <label>
              <b>Day</b>
            </label>
            <Form.Select value={day} onChange={autoFillDate}>
                <option value="">Select Days</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="thisWeek">This Week</option>
                <option value="lastWeek">Last Week</option>
                <option value="thisMonth">This Month</option>
                <option value="thisLastMonth">This Last Month</option>
            </Form.Select>
   </>
  )
}
