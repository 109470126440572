import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getSettings, getSubadminSettings, updateSetting } from "../../reducers/commonReducer";
import SettingAction from "./SettingAction";
import SideBaar from "../../Components/SideBaar";

const SettingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    if((localStorage.getItem("userRole") === "1")){
      dispatch(getSettings())
    }
    if((localStorage.getItem("userRole") === "2")){
      dispatch(getSubadminSettings())
    }
  }, [navigate, dispatch]);
  const {getSettingList, getSubadminSettingsList} = useSelector((state) => state.commonReducer);
  let settingRecord = (localStorage.getItem("userRole") === "1") ? getSettingList : getSubadminSettingsList
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-1 mb-0 user-table">
          <Col md={6}>
            <h3 className="mb-0">Settings</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>Name</th>
                <th>Help Text</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!settingRecord && settingRecord?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.label}</td>
                      <td>{data?.halptext}</td>
                      <td>{data?.value}</td>
                      <td>
                        <SettingAction 
                          id={data?.id} value={data?.value}
                          api={updateSetting}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default SettingList;
