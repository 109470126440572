import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { getPaymentOptions, userPayments } from '../../reducers/commonReducer';
import logo from '../../images/logo.png';
import { Button, Col, Row, Container } from 'react-bootstrap';
import MinAndMaxAmount from './MinAndMaxAmount';

export const UserPayment = () => {
  const dispatch = useDispatch();
  const [paymentUrl, setPaymentUrl] = useState();
  const { userid, amount } = useParams();

  useEffect(()  => {
    // const OnLoadData = async () => {
     dispatch(getPaymentOptions())
     dispatch(userPayments({userid, amount : +amount}))
    // await document?.forms["myForm"].submit()
    // }
    // OnLoadData(); 
  }, []);

  const {userPaymentsDetails, paymentOptions} = useSelector((state) => state.commonReducer);
  const paymentSubmit = (url) =>{
    setPaymentUrl(url);
    setTimeout(() => document?.forms["myForm"].submit(), 100)
  }
  return (
    <div className="response-bg">
      <img className='mb-4' src={logo} alt="Logo" />
      {userPaymentsDetails?.paymentUrl && userPaymentsDetails?.phonePay ? 
         window.location.replace(userPaymentsDetails?.paymentUrl) 
        : 
        <form name="myForm" id="myForm"  
          // action={userPaymentsDetails?.paymentUrl} 
          action={paymentUrl}
          method="POST"
        >
        <input type="hidden" name="firstName" value={userPaymentsDetails?.firstName ? userPaymentsDetails?.firstName : ''} />
        <input type="hidden" name="transaction_id" value={userPaymentsDetails?.transaction_id ? userPaymentsDetails?.transaction_id : ''} />
        <input type="hidden" name="amount" value={userPaymentsDetails?.amount ? userPaymentsDetails?.amount : ''} />
        <input type="hidden" name="email" value={userPaymentsDetails?.email ? userPaymentsDetails?.email : ''} />
        <input type="hidden" name="phone" value={userPaymentsDetails?.phone ? userPaymentsDetails?.phone : ''} />
        <Container>
          <Row>
            {
              !!paymentOptions && paymentOptions?.map((data, index) => {
                return(
                    data?.status && <Col className='mb-4' md={12} sm={12} xs={12} key={index}>
                    {
                      (data?.min <= +amount) ? <Button 
                      className ={`${data?.max <= +amount && `d-none`} py-2 font-45 w-100`} variant={'success'} 
                      onClick={() => 
                        paymentSubmit(data?.paymenturl)
                      }
                      ><i className="fa fa-money" aria-hidden="true"></i> {data?.label}</Button> : <MinAndMaxAmount label={data?.label} text={`Please Deposit Minimum ${data?.min}Rs. or More.`}/>
                        }
                      </Col>
                    // <Col className='mb-4' md={12} sm={12} xs={12} key={index}>
                    //       <Button className="w-100" variant='success' onClick={() => paymentSubmit(data?.paymenturl)}><i className="fa fa-money" aria-hidden="true"></i> {data?.label}</Button>
                    //     </Col>
                      )
                  })
                }
          </Row>
        </Container>
        <input type="hidden" value="Submit" />
      </form>    
      }
    </div>
  );
};

