export var baseUrl = "http://localhost:3000";
//export var baseUrl = "https://livetambola.havflyinvitation.com";
// In case instead of localhost
if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}

export const applyBaseColor = (colorCode) => {
  document.documentElement.style.setProperty('--green', colorCode);
};

export const applySecondaryColor = (colorCode) => {
  document.documentElement.style.setProperty('--yellow', colorCode);
};

export const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
