import React, { useRef } from 'react'
import TimerCard from './TimerCard'
import { Row, Col } from 'react-bootstrap'
import logo from '../images/logo.png'
import ListTab from './ListTab';

export default function Header() {
const width = useRef(window.innerWidth);
  return (
    <>
            <Row className='text-center justify-content-center'>
                <Col md={6} className='static-header base-color'>
                    <img className={width.current < 470 ? 'w-80' : 'w-auto'} alt="logo" src={logo}/>
                </Col>
            </Row>
            <Row className='justify-content-center mt-8-vh'>
              <TimerCard/>
            </Row>
            <Row className='justify-content-center mb-2'>
              <ListTab/>
            </Row>
    </>
  )
}
