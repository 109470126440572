import React from 'react';

const NotFoundPage= () =>{
	return(
		<div className='notFountPage'>
			<h1>404 Error</h1>
			<h1>Page Not Found</h1>
		</div>
	)
}

export default NotFoundPage;
