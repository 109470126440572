import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getSettings, getSubadminSettings } from "../../reducers/commonReducer";

const schema = yup.object().shape({
});

function SettingAction(props) {
  const dispatch = useDispatch();
  const {id, value, api} = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="py-0" variant={'success'} onClick={handleShow}>
      <i className="bi bi-pen-fill"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>Update Settings</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(api(values));
            resetForm({ values: "" });
            if((localStorage.getItem("userRole") === "1")){
              dispatch(getSettings())
            }
            if((localStorage.getItem("userRole") === "2")){
              dispatch(getSubadminSettings())
            }
            setShow(false)
          }}
          initialValues={{
            value, id
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={12}>
                <Form.Group >
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Value"
                      name="value"
                      value={values.value}
                      onChange={handleChange}
                      isInvalid={!!errors.value}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.value}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default SettingAction;
