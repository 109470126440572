import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addBanner, getGames } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  minimumamount: yup.string().required("Please Enter Minimum Amount."),
  status: yup.boolean().required("Please Select Game Status."),
});

function GameAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {btnTitle, btnFloat, id, minimumamount, status, api} = props;
  return (
    <>
      <Button variant="success" onClick={handleShow} className={btnFloat}>
       {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : "Update Game"}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(api(values));
            resetForm({ values: "" });
            dispatch(getGames())
            setShow(false)
          }}
          initialValues={{
            id, minimumamount, status
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Minimum Amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Minimum Amount"
                      name="minimumamount"
                      value={values.minimumamount}
                      onChange={handleChange}
                      isInvalid={!!errors.minimumamount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.minimumamount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Select Status</Form.Label>
                      <Form.Control
                              as="select"
                              onChange={(e) => setFieldValue('status', e.target.value)}
                              name="status"
                              className="form-control"
                              value={values.status}
                              isInvalid={!!errors.status}
                            >
                              <option value="">Select Status</option>
                              <option value={true}>Active</option>
                              <option value={false}>InActive</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default GameAction;
