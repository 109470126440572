import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Button } from "react-bootstrap";
import Header from "../../../Components/Header";
import { colorCurrentGame, restRecord } from "../../../reducers/commonReducer";
import SideBaar from "../../../Components/SideBaar";

const ColorCurrentGame = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const gameId = queryParameters.get("id");

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord());
    dispatch(
      colorCurrentGame({
        gameHistory: gameId,
      })
    );
  }, []);

  const { colorCurrentGameList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Row>
        <Col md={2} className="p-0">
          <SideBaar />
        </Col>
        <Col md={10}>
          <Header />
          <div className="container">
          <Row className="mt-2">
              <Col md={12}>
                <Button
                  className="w-100"
                  variant="success"
                  onClick={() => navigate(`/color-game-history`)}
                >
                  <b>
                    Game History <i class="bi bi-eye-fill"></i>
                  </b>
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center">
              {!!colorCurrentGameList?.betTypes &&
                Object.entries(colorCurrentGameList.betTypes).map(
                  ([key, value]) => {
                    return (
                      <Col md={3} className="mt-3" key={key}>
                        <div className="box-shadow text-center py-5">
                          <h3>
                            <b>{key}</b>
                          </h3>
                          <h4 className="text-success">
                            <b>{value}</b>
                          </h4>
                        </div>
                      </Col>
                    );
                  }
                )}
            </Row>
            <Row className="my-2">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>UserName</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {!!colorCurrentGameList?.currentUsersData &&
                      Object.entries(colorCurrentGameList.currentUsersData).map(
                        ([key, value]) => {
                          return(
                            <>
                            <td>{key}</td>
                            <td>{value}</td>
                            </>
                          )
                        }
                      )}
                  </tr>
                </tbody>
              </Table>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ColorCurrentGame;
