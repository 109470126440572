import React, { useEffect, useState } from "react";
import "../../app.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { planSubscribe } from "../../reducers/commonReducer";
import tambolasub from "../../images/tambolasub.png";
import { Row } from "react-bootstrap";
import lucky from '../../images/lucky.png';
import tree from '../../images/tree.png';
import omg from '../../images/omg.png';

// Validation by Yup
const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email('Invalid email'),
  phone: yup.string().matches(/^[0-9]+$/, 'Phone must contain only digits').min(10, 'Phone must be at least 10 digits').max(10, 'Phone cannot exceed 10 digits').required("Phone is required."),
  domain: yup.string().required("Tambola name is required."),
  planid : yup.string().required("Plan is required."),
});

// Login Page
const SubscriptionDetailsAction = () => {
  const [theme, setTheme] = useState();
  const dispatch = useDispatch();
  let width = window.innerWidth;
  const queryParameters = new URLSearchParams(window.location.search);
  const planid = queryParameters.get("planid");
  const price = queryParameters.get("price");
  let dateYear = new Date();
  const onFormSubmit = async (values) => {
    const apiResponse = dispatch(planSubscribe(values));
    return null;
  };
  const {planSubscribeDetails} = useSelector((state) => state.commonReducer);
  useEffect(() => {
    const OnLoadData = async () => {
      await document?.forms["myForm"].submit()
    }
    if(planSubscribeDetails){
      OnLoadData()
    }
  }, [planSubscribeDetails])
  return (
    <>
    {/* payment related */}
    {
      !!planSubscribeDetails && <form name="myForm1" id="myForm"  
        action={planSubscribeDetails?.paymentUrl} 
        method="POST">
        <input type="hidden" name="firstName" value={planSubscribeDetails?.firstName ? planSubscribeDetails?.firstName : ''} />
        <input type="hidden" name="transaction_id" value={planSubscribeDetails?.transaction_id ? planSubscribeDetails?.transaction_id : ''} />
        <input type="hidden" name="amount" value={planSubscribeDetails?.amount ? planSubscribeDetails?.amount : ''} />
        <input type="hidden" name="email" value={planSubscribeDetails?.email ? planSubscribeDetails?.email : ''} />
        <input type="hidden" name="phone" value={planSubscribeDetails?.phone ? planSubscribeDetails?.phone : ''} />
        <input type="hidden" value="Submit" />
      </form>
    }
      <div className="">
        <div className="row sub-bg pt-3 pb-3">
          <Col md={12} className={`${width > 500 ? "w-40 loginPanel p-5 " : "w-100 p-2"}`}>
            <div className="form-logo text-center">
            <img className={`${width > 500 ? "w-40" : "w-100 px-2"}`} src={tambolasub} alt="logo"/>
            </div>
            <div className="text-center mb-4 mt-2">
              <h4>
              ****Please Fill Up The Form****
              </h4>
            </div>
            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                onFormSubmit({
                  name: values?.name, phone: values?.phone, domain: values?.domain, email: values?.email, planid, theme
                });
                resetForm({ values: "" });
              }}
              initialValues={{
                name: "",
                phone: "",
                domain: '',
                email: '',
                planid,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className={`${width > 500 ? "px-2" : "px-4"}`}>
                  <Row>
                    <Col md={12} sm={12} xs={12} className={`${width > 500 ? `mb-4` : `mb-3`}`}>
                        <Form.Group as={Col} md="12">
                            <div className="input-group">
                                <div className="input-group-text">
                                <i className="bi bi-person-fill"></i>
                                </div>
                                <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                                className="form-control"
                                placeholder="Enter Your Name"
                                required
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.name}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12} xs={12} className={`${width > 500 ? `mb-4` : `mb-3`}`}>
                        <Form.Group as={Col} md="12">
                            <div className="input-group">
                                <div className="input-group-text">
                                <i className="bi bi-envelope-fill"></i>
                                </div>
                                <Form.Control
                                type="text"
                                name="email"
                                id="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                                className="form-control"
                                placeholder="Enter Your Email"
                                required
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.email}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12} xs={12} className={`${width > 500 ? `mb-4` : `mb-3`}`}>
                        <Form.Group as={Col} md="12">
                            <div className="input-group">
                                <div className="input-group-text">
                                <i className="bi bi-phone-fill"></i>
                                </div>
                                <Form.Control
                                type="text"
                                id="phone"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                isInvalid={!!errors.phone}
                                className="form-control"
                                placeholder="Enter Your Phone"
                                required
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.phone}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12} xs={12} className={`${width > 500 ? `mb-4` : `mb-3`}`}>
                        <Form.Group as={Col} md="12">
                            <div className="input-group">
                                <div className="input-group-text">
                                <i className="bi bi-dice-1-fill"></i>
                                </div>
                                <Form.Control
                                type="text"
                                name="domain"
                                id="domain"
                                value={values.domain}
                                onChange={handleChange}
                                isInvalid={!!errors.domain}
                                className="form-control"
                                placeholder="Tambola Name"
                                required
                                />
                                <p className="domain-field">.tambola.one</p>
                                <Form.Control.Feedback type="invalid">
                                {errors.domain}
                                </Form.Control.Feedback>
                            </div>
                            {values?.domain?.length > 0 && <p className="mb-0 text-success"><b>{values?.domain}.tambola.one</b></p>}
                        </Form.Group>
                    </Col>
                  </Row>
                  <Row className="form-logo text-center">
                    <Col md={12}><b className="text-danger">Please Select Theme</b></Col>
                  </Row>
                  <Row className="form-logo text-center">
                        <Col md={4} sm={4} xs={4} className={theme === "LuckyTambola" ? `bg-success p-2` : `` }>
                        <img onClick={() => setTheme("LuckyTambola")} className={`mt-2 ${width > 500 ? "w-100" : "w-100 px-0"}`} src={lucky}  alt="ticket"/>
                        </Col>
                        <Col md={4} sm={4} xs={4} className={theme === "TreeTambola" ? `bg-success p-2` : `` }>
                        <img onClick={() => setTheme("TreeTambola")} className={`mt-2 ${width > 500 ? "w-100" : "w-100 px-0"}`} src={tree}  alt="ticket"/>
                        </Col>
                        <Col md={4} sm={4} xs={4} className={theme === "OmgTambola" ? `bg-success p-2` : `` }>
                        <img onClick={() => setTheme("OmgTambola")} className={`mt-2 ${width > 500 ? "w-100" : "w-100 px-0"}`} src={omg}  alt="ticket"/>
                        </Col>
                      </Row>
                  <Row>
                    <div className="mt-2">
                        <h4>
                        Total cost is {price} Rs./monthly
                        </h4>
                    </div>
                  </Row>
                  <div className="col-12 text-center mt-2">
                    <Button
                      type="submit"
                      className="button-color login-btn px-4 w-100"
                    >
                      Buy Now
                    </Button>
                  </div>

                  <div className="col-12 text-center mt-4">
                    <p>
                      All Rights Reserved by 💗 Tambola Game ©{" "}
                      {dateYear.getFullYear()}
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetailsAction;
