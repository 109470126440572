import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getQuestions } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  question : yup.string().required(),
});

function QuestionAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {btnTitle, btnFloat, id, reply, question, orderBy, status, api} = props;
  return (
    <>
      <Button variant="success" onClick={handleShow} className={btnFloat}>
       {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : "Update"}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiresult = await dispatch(api(values));
            if(apiresult?.payload?.status){
              resetForm({ values: "" });
              dispatch(getQuestions())
              setShow(false)
            }
          }}
          initialValues={{
            id, reply, question, orderBy, status
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Question"
                      name="question"
                      value={values.question}
                      onChange={handleChange}
                      isInvalid={!!errors.question}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.question}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Enter Reply</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Reply"
                      name="reply"
                      value={values.reply}
                      onChange={handleChange}
                      isInvalid={!!errors.reply}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reply}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                  <Col md={6}>
                    <Form.Group >
                      <Form.Label>OrderBy</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter orderBy"
                        name="orderBy"
                        value={values.orderBy}
                        onChange={handleChange}
                        isInvalid={!!errors.orderBy}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.orderBy}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group >
                      <Form.Label>Select Status</Form.Label>
                        <Form.Control
                                as="select" 
                                onChange={(e) => setFieldValue('status', (/true/).test(e.target.value))}
                                name="status"
                                className="form-control"
                                value={values.status}
                                isInvalid={!!errors.status}
                              >
                                <option value="">Select Status</option>
                                <option value={true}>Active</option>
                                <option value={false}>InActive</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.status}
                              </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default QuestionAction;
