import React from 'react'
import { Col, Row} from 'react-bootstrap'

export default function TicketCard({ticketnum, username}) {
  return (
            <Col md={6} className='secondary-color'>
                <Row className='ticket-head ticket-head-green-yellow'>
                    <Col md={6} sm={6} xs={6}><p className='mb-0'>{ticketnum}</p></Col>
                    <Col md={6} sm={6} xs={6}><p className='mb-0 text-right ticket-name'>{username}</p></Col>
                </Row>
                <Row className='mb-2 justify-content-center'>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                </Row>
                <Row className='mb-2 justify-content-center'>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                </Row>
                <Row className='mb-2 justify-content-center'>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                    <div className='ticket-num'><span>01</span></div>
                    <div className='ticket-num'><span></span></div>
                </Row>
            </Col>
        )
    }
