import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
const TimerCount = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const startGame = queryParameters.get('startGame');
  let currentTime = new Date();
  let startTime = new Date(startGame);
  let gameStartTime = Math.floor((startTime.getTime() - currentTime.getTime()) / 1000);
  const [seconds, setSeconds] = useState(gameStartTime);

  const idRef = useRef(null);
  const clear = () => {
    window.clearInterval(idRef.current);
  };
  useEffect(() => {
    idRef.current = window.setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      clear();
    }
  }, [seconds]);
  seconds === 0 && window.location.reload();
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return props?.endTime
      ? 'Completed'
      : moment().format() >= moment(props?.gameTime).format()
      ? 'Live'
      : moment().add(5, 'm').format() >= moment(props?.gameTime).format()
      ? `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
      : 'Wait';
  };
  return (
    <div>
      <h4>
        <span className="counter-box">{formatTime(seconds)}</span>
      </h4>
    </div>
  );
};

export default TimerCount;
