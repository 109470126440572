import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function MinAndMaxAmount({label, text}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="secondary" className='py-2 font-45 btn w-100' onClick={handleShow}>
      <i className="fa fa-money" aria-hidden="true"></i> {label}
      </Button>
      <Modal show={show} onHide={handleClose} className='min-max-popup'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='text-center'>
            <h3>{text}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default MinAndMaxAmount;