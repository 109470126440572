// src/MyComponent.js
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { socket } from '../helpers/socket';

// const socket = io('https://livetambola.havflyinvitation.com:3001'); // Update with your server URL if different

const MyComponent = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Listen for incoming messages
    // socket.on('numberpdCurrentGame', (msg) => {
    //   console.log("msgmsg", msg)
    //   setMessages((prevMessages) => [...prevMessages, msg]);
    // });

    // Clean up the socket when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  const sendMessage = () => {
    socket.emit('numberpdCurrentGame', {}, (response) => {
      console.log("response", response ,response.status); // ok
    });
    // setMessage('');
  };
  return (
    <div>
      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{msg}</li>
        ))}
      </ul>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default MyComponent;
