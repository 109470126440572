import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getGameTransactions } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import moment from "moment";

const GameTransactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("gameid");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getGameTransactions(id))
  }, [navigate, dispatch, id]);
  const { getGameTransactionsList} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">Game Transactions</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>IDD</th>
                <th>Date</th>
                <th>Type</th>
                <th>FromUser</th>
                <th>ToUser</th>
                <th>TransCoins</th>
                <th>RemainingCoins</th>
              </tr>
            </thead>
            <tbody>
              {!!getGameTransactionsList &&
                getGameTransactionsList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data?.idd}</td>
                      <td>{moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{data?.type}</td>
                      <td>{data?.fromUser && `${data?.fromUser?.username} (${data?.fromUser?.name})`}</td>
                      <td>{data?.toUser && `${data?.toUser?.username} (${data?.toUser?.name})`}</td>
                      <td><b>{data?.trans_coins}</b></td>
                      <td>{data?.remaining_coins}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default GameTransactions;
