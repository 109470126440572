import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getAgents, getUsers, restRecord } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required("Please Enter Name."),
  password: yup.string().required("Please Enter Password."),
  status: yup.boolean().required("Please Select Status."),
});

function UserAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {btnTitle, api, status, id, type, commission, password, username, name, buttonFloat, userType, subadminId} = props;
  return (
    <>
      <Button variant="success" className={buttonFloat} onClick={handleShow}>
       {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : `${username} ${type}`}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResult = await dispatch(api(values));
            if(apiResult?.payload?.status === 1){
              resetForm({ values: "" });
              dispatch(restRecord());
              (userType === "1" || localStorage.getItem("website")?.length > 0) ? 
              dispatch(getAgents({
                subadminid:subadminId
              })) :
              dispatch(getUsers({
                subadminid:subadminId
              }));
              setShow(false)
            }
          }}
          initialValues={{
            id, username, commission, name, status, password
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {
                  (localStorage.getItem("website")?.length > 0) && <Col md={6}>
                  <Form.Group >
                    <Form.Label>Commission</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Commission"
                      name="commission"
                      value={values.commission}
                      onChange={handleChange}
                      isInvalid={!!errors.commission}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.commission}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                }
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Select Status</Form.Label>
                      <Form.Control
                              as="select"
                              onChange={handleChange}
                              name="status"
                              className="form-control"
                              value={values.status}
                              isInvalid={!!errors.status}
                            >
                              <option value="">Select status</option>
                              <option value={true}>Active</option>
                              <option value={false}>InActive</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UserAction;
