import React from 'react'
import { Button, Col, Row} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function ListTab() {
    const navigate = useNavigate();
  return (
            <Col md={6}>
                <Row className='mb-0 pt-3 pb-3 align-center mbl-text text-center'>
                    <Col md={4} sm={4} xs={4} className='px-1'>
                        <Button onClick={() => navigate('/agents')} className='secondary-color text-black px-1 w-100'><i className="bi bi-people-fill"></i> Agents</Button>
                    </Col>
                    <Col md={4} sm={4} xs={4} className='px-1'>
                        <Button onClick={() => navigate('/agents')} className='secondary-color text-black px-1 w-100'><i className="bi bi-whatsapp"></i> Whatsapp</Button>
                    </Col>
                    <Col md={4} sm={4} xs={4} className='px-1'>
                        <Button onClick={() => navigate('/agents')} className='secondary-color text-black px-1 w-100'><i className="bi bi-trophy-fill"></i> Rewards</Button>
                    </Col>
                </Row>
            </Col>
        )
    }
