import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Table, Col, Row} from "react-bootstrap";
import Header from "../../Components/Header";
import { addQuestion, getQuestions, updateQuestion } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import QuestionAction from "./QuestionAction";

const QuestionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getQuestions());
  }, [navigate, dispatch]);
  const { questionsData } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Questions</h3>
          </Col>
          <Col md={6}>
            <QuestionAction api={addQuestion} btnTitle={"Add"} btnFloat={"float-right"}/>
          </Col>
        </Row>
        <hr/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr.</th>
                <th>Question</th>
                <th>Reply</th>
                <th>OrderBy</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!questionsData &&
                questionsData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.question}?</td>
                      <td>{data?.reply}</td>
                      <td>{data?.orderBy}</td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td><QuestionAction api={updateQuestion} id={data?.id} question={data?.question} reply={data?.reply} orderBy={data?.orderBy} status={data?.status}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default QuestionList;
