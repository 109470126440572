import React from 'react'
import { Button, Col, Row} from 'react-bootstrap'


export default function WinnerCard() {
  return (
            <Col md={6} className='secondary-color border-radius text-center pt-1 pb-1 mb-2'>
                        <h4 className='mb-0 mt-1'>QUICK FIVE</h4>
                        <p className='mb-0'>59 Abhi milega 🙏🙏🙏</p>
                        <Button variant='success'>View</Button>
            </Col>
        )
    }
