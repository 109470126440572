import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getUserGameHistory } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import moment from "moment";

const UserGameHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("userid");

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getUserGameHistory(userid))
  }, [navigate, dispatch, userid]);
  const { userGameHistoryList} = useSelector((state) => state.commonReducer);

  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-2 bg-success text-white py-2 text-center">
          <Col md={3} xs={3} sm={3}>
            <p className="mb-0"><b>Start Time:</b>  {moment(userGameHistoryList?.start).format('DD/MM/YYYY hh:mm A')}</p>
          </Col>
          <Col md={3} xs={3} sm={3}>
            <p className="mb-0"><b>End Time:</b> {moment(userGameHistoryList?.end).format('DD/MM/YYYY hh:mm A')}</p>
          </Col>
          <Col md={3} xs={3} sm={3}>
            <p className="mb-0"><b>Total Betting:</b> {userGameHistoryList?.totalBetting}</p>
          </Col>
          <Col md={3} xs={3} sm={3}>
            <p className="mb-0"><b>Total Winning:</b> {userGameHistoryList?.totalWinning}</p>
          </Col>
        </Row>
        <Row className="mt-2 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">User Game History</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>UserName</th>
                <th>Bet Amount</th>
                <th>Winning Amount</th>
                <th>Placed Bet</th>
              </tr>
            </thead>
            <tbody>
              {!!userGameHistoryList?.users &&
                userGameHistoryList?.users?.map((data, index) => {
                  return (
                    <tr key={index}>
                    <td>
                    <Link to={`/user-transaction?userid=${data?.userid}&username=${data?.username}`}>{data?.username + ' ('+ data?.name + ')'}</Link>
                    </td>
                    <td>{data?.betamount}</td>
                    <td>{data?.winning}</td>
                    <td>
                        <Link
                            to={`/game-history-board?gameId=${userGameHistoryList?.gameid}&gameHistoryid=${userid}&userid=${data?.userid}`}
                            className="setting-btn"
                        >
                            <i className="fa fa-gamepad" aria-hidden="true"></i> Placed Bet
                        </Link>
                    </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default UserGameHistory;
