import React from 'react'
import { Button, Col, Container, Row} from 'react-bootstrap'
import Header from './Header'

export default function Agents() {
  return (
            <div className='base-color min-height'>
                <Container className='pb-3'>
                    <Header/>  
                    <Row className="justify-content-center side-spacing mb-2">
                    <Col md={6}>
                        <Row className='mb-1 pt-1 pb-1 mbl-text align-items-center secondary-color'>
                            <Col md={6} sm={6} xs={6}>
                            <b>Rekha Singh</b>
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                                <Button variant='success' className='float-right px-2 py-1'><i className="bi bi-telephone-outbound-fill"></i> Call Now</Button>
                            </Col>
                        </Row>
                        <Row className='mb-1 pt-1 pb-1 mbl-text align-items-center secondary-color'>
                            <Col md={6} sm={6} xs={6}>
                            <b>Rekha Singh</b>
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                                <Button variant='success' className='float-right px-2 py-1'><i className="bi bi-telephone-outbound-fill"></i> Call Now</Button>
                            </Col>
                        </Row>
                        <Row className='mb-1 pt-1 pb-1 mbl-text align-items-center secondary-color'>
                            <Col md={6} sm={6} xs={6}>
                            <b>Rekha Singh</b>
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                                <Button variant='success' className='float-right px-2 py-1'><i className="bi bi-telephone-outbound-fill"></i> Call Now</Button>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </Container>
            </div>
        )
    }
