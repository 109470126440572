import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import TicketCard from './TicketCard'
import Header from './Header';
import Board from './Board';
import DeclaredNumber from './DeclaredNumber';
import WinnerCard from './WinnerCard';

export default function GameBoard() {

  return (
    <div className='base-color min-height'>
        <Container className='pb-3'>
            <Header/>  
            <Row className='justify-content-center mb-3'>
              <Board ticketnum={"001"} username={"Rekha"}/>
            </Row>
            <Row className='justify-content-center mb-3'>
              <DeclaredNumber/>
            </Row>
            <Row className='justify-content-center mb-3 side-spacing'>
              <Col md={6} className='text-center'>
                <h4 className='pt-2 text-white'>SELECT YOUR COUPON NUMBER</h4>
                <Row className='mb-2 justify-content-center'>
                    <input className='select-num' type='text' value={0} disabled/>
                    <input className='select-num' type='text' value={1} disabled/>
                    <input className='select-num' type='text' value={2} disabled/>
                    <input className='select-num' type='text' value={3} disabled/>
                    <input className='select-num' type='text' value={4} disabled/>
                    <input className='select-num' type='text' value={5} disabled/>
                    <input className='select-num' type='text' value={6} disabled/>
                    <input className='select-num' type='text' value={7} disabled/>
                    <input className='select-num' type='text' value={8} disabled/>
                    <input className='select-num' type='text' value={9} disabled/>
                </Row>
                <Row className='align-center'>
                    <Col md={4} sm={4} xs={4} className='p-0'><Button className='float-left' variant='danger'>DEL</Button></Col>
                    <Col md={4} sm={4} xs={4} className='border'><h4 className='mb-0 text-center bold text-white pt-1 pb-1'>{11}</h4></Col>
                    <Col md={4} sm={4} xs={4} className='text-right p-0'><Button variant='info' className=''>OK</Button></Col>
                </Row>
              </Col>
            </Row>
            <Row className='justify-content-center mb-3 side-spacing'><WinnerCard/></Row>
            <Row className='justify-content-center mb-3 side-spacing'>
              <TicketCard ticketnum={"44"} username={"KKK"}/>
            </Row>
        </Container>
    </div>
  )
}
