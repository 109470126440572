import React, { Fragment, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import Table from "react-bootstrap/Table";
import NumberBoard from './NumberBoard';
import Header from "../../../Components/Header";
import SideBaar from "../../../Components/SideBaar";
import rewards from '../../../images/rewards.jpg';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getNumberpdType, numberPdGame } from "../../../reducers/commonReducer";

const NumberPredictionBoard = () => {
  const inputRef = useRef(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("gameid");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(numberPdGame({gameHistoryId : id}));
    dispatch(getNumberpdType());
    inputRef?.current?.focus();
  }, [dispatch, id]);

  useEffect(() => {
      const dispatchNumberPD = () => {
        dispatch(numberPdGame({gameHistoryId : id}));
      };
      dispatchNumberPD();
      const intervalId = setInterval(dispatchNumberPD, 5000);
      return () => clearInterval(intervalId);
  }, [dispatch, id]);

  const { numberPdBettings, numberPdCurrentGame, numberPdSpotWithBetTotalAmount, numberPdUserWithBetTotalAmount } = useSelector((state) => state.commonReducer);
  const numbersArray = numberPdCurrentGame?.number?.split(',');
  const decalerdArray = !!numbersArray && numbersArray?.map(Number);
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
    <Header />
    {
      numberPdCurrentGame?.status === 0 ? <div className="mt-3">
      <Row>
        <Col><h2 className='text-danger text-center'>{numberPdCurrentGame?.message}</h2></Col>
      </Row>
    </div> :
    <div className="mt-3">
      <Row>
        <Col md={4} className="mt-2">
          {/*{numberPdCurrentGame?.betting_allow_time && <NumberPdTimer bettingTime={numberPdCurrentGame?.betting_allow_time}/>} */}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={8}>
          <p className="mb-0">
            Time:- <b>{`${moment(numberPdCurrentGame?.start).format(
              'MMMM Do YYYY, h:mm:ss a',
            )}`} - {`${moment(numberPdCurrentGame?.end).format(
                'MMMM Do YYYY, h:mm:ss a',
              )}`}</b>
          </p>
        </Col>
        <Col md={2}>
          <p className="mb-0">
          Total Betting:- <b>{numberPdCurrentGame?.total_betting}</b>
          </p>
        </Col>
        <Col>
          <p className="mb-0">
          Total Winning:- <b>{numberPdCurrentGame?.total_winning}</b>
          </p>
        </Col>
      </Row>
      <Row>
        <p className='mb-0'>
        <b>Declared Numbers:</b> {numberPdCurrentGame?.number ? numberPdCurrentGame?.number : 'Please Wait....'}
        </p>
      </Row>
      <hr />
      <Container>
        <Row>
              <Col md={6}>
                <NumberBoard crossnumbers={numberPdCurrentGame} />
              </Col>
              <Col md={6} className="tambola-row-mrgn">
                <div>
                  <Row>
                    <img src={rewards} alt="rewards"/>
                  </Row>
                  {
                    !!numberPdSpotWithBetTotalAmount && Object.entries(numberPdSpotWithBetTotalAmount).map(([key, value]) => (
                      <Row className="tambola-row-mrgn">
                            <Col md={6}>
                              <div className="reward-tab">{key} Spot</div>
                            </Col>
                            <Col md={6}>
                              <div className="reward-tab"><span style={{fontWeight:200}}>{value}</span></div>
                            </Col>
                          </Row>
                        ))
                      }
                </div>
              </Col>
            </Row>
      </Container>
      <Row>
        <Col md={2}>
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
          <tr>
            <th>UserName</th>
            <th>Amount</th>
          </tr>
          </thead>
          <tbody>
          {
            !!numberPdUserWithBetTotalAmount && Object.entries(numberPdUserWithBetTotalAmount).map(([key, value]) => (
              <tr>
                <td>{key}</td>
                <td>{value}</td>
            </tr>
            ))
          }
          </tbody>
        </Table>
        </Col>
        <Col md={10}>
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Winning</th>
            <th>Betting</th>
          </tr>
          </thead>
          <tbody>
          {
            !!numberPdBettings && numberPdBettings?.map((data, index) => {
              return(
                <tr key={index}>
                  <td><Link to={`/user-transaction?userid=${data?.id}&username=${data?.username}`}>{data?.prime && <i className="fa fa-users" aria-hidden="true"></i>} {data?.name} ({data?.username})</Link></td>
                  <td>{data?.amount}</td>
                  <td>{data?.winning}</td>
                  <td>{data?.numbers?.map((item , itemIndex) => <Fragment key={itemIndex}><span className={!!decalerdArray && decalerdArray?.includes(item) ? "cross-btn text-white p-1 rounded-circle" : ""}>{item}</span>, </Fragment>)}</td>
                </tr>
              )
            })
          }
          </tbody>
        </Table>
        </Col>
      </Row>
    </div>
    }
    </Col>
    </Row>
    </>
  );
};

export default NumberPredictionBoard;
