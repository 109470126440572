import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import trophy from '../Web/img/trophy.png'

function GameRewardList() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
        <Row onClick={handleShow} className='align-items-center reward-button base-color'>
            <Col md={4} xs={4} sm={4}>
                <img className='w-35px' src={trophy} alt="Tambola Winners"/>
            </Col>
            <Col md={8} xs={8} sm={8} className='p-0'>
                <p className='mb-0 line-height-15'>
                Rs. 5100<br/>
                Rewards: 9
                </p>
            </Col>
        </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='bg-success text-white'>
          <Modal.Title>Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className='text-center mt-1'>
                <Col md={6} sm={6} xs={6}>
                <h4 className='mb-0 pt-1 pb-1'>Full House</h4>
                </Col>
                <Col md={6} sm={6} xs={6}>
                    <h4 className='mb-0 pt-1 pb-1'><b>5000/-</b></h4>
                </Col>
            </Row>
            <Row className='text-center mt-1'>
                <Col md={6} sm={6} xs={6}>
                <h4 className='mb-0 pt-1 pb-1'>Full House</h4>
                </Col>
                <Col md={6} sm={6} xs={6}>
                    <h4 className='mb-0 pt-1 pb-1'><b>5000/-</b></h4>
                </Col>
            </Row>
            <Row className='text-center mt-1'>
                <Col md={6} sm={6} xs={6}>
                <h4 className='mb-0 pt-1 pb-1'>Full House</h4>
                </Col>
                <Col md={6} sm={6} xs={6}>
                    <h4 className='mb-0 pt-1 pb-1'><b>5000/-</b></h4>
                </Col>
            </Row>
            <Row className='text-center mt-1'>
                <Col md={6} sm={6} xs={6}>
                <h4 className='mb-0 pt-1 pb-1'>Full House</h4>
                </Col>
                <Col md={6} sm={6} xs={6}>
                    <h4 className='mb-0 pt-1 pb-1'><b>5000/-</b></h4>
                </Col>
            </Row>
            <Row className='text-center mt-1'>
                <Col md={6} sm={6} xs={6}>
                <h4 className='mb-0 pt-1 pb-1'>Full House</h4>
                </Col>
                <Col md={6} sm={6} xs={6}>
                    <h4 className='mb-0 pt-1 pb-1'><b>5000/-</b></h4>
                </Col>
            </Row>
            <Row className='text-center mt-1'>
                <Col md={6} sm={6} xs={6}>
                <h4 className='mb-0 pt-1 pb-1'>Full House</h4>
                </Col>
                <Col md={6} sm={6} xs={6}>
                    <h4 className='mb-0 pt-1 pb-1'><b>5000/-</b></h4>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer className='py-0'>
          <Button variant="danger w-100" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GameRewardList;