import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getAmount, updateAmount } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  amount: yup.number().required(),
  cashback: yup.number().required(),
});

function AmountAction(props) {
  const dispatch = useDispatch();
  const {status, id, amount, cashback} = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={status ? "success" : "danger"} onClick={handleShow}>
       {status ? "Active" : "InActive"}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Amount</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(updateAmount(values));
            resetForm({ values: "" });
            dispatch(getAmount())
            setShow(false)
          }}
          initialValues={{
            status, id, amount, cashback
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                              as="select"
                              onChange={handleChange}
                              name="status"
                              className="form-control"
                              value={values.status}
                              isInvalid={!!errors.status}
                            >
                              <option value="">Select Status</option>
                              <option value={true}>Active</option>
                              <option value={false}>Inactive</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Amount"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      isInvalid={!!errors.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Cashback</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Cashback"
                      name="cashback"
                      value={values.cashback}
                      onChange={handleChange}
                      isInvalid={!!errors.cashback}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.cashback}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Cashback in %</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      value={`${(values.cashback / values.amount * 100).toFixed(2)} %`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default AmountAction;
