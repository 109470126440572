import React, { useEffect } from 'react';
import Header from "../../Components/Header";
import SideBaar from "../../Components/SideBaar";
import { Row, Col} from 'react-bootstrap';
import NumberPredictionGameHistoryBoard from './NumberPrediction/NumberPredictionGameHistoryBoard';

function GameHistoryBoard() {
  const queryParameters = new URLSearchParams(window.location.search);
  const gameId = queryParameters.get("gameId");
  const userid = queryParameters.get("userid");
  const gameHistoryid = queryParameters.get("gameHistoryid");
  useEffect(() => {

  }, [gameId, userid, gameHistoryid])
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
    <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">{gameId === "651f9c4ea5e67037d308e120" ? "Number Prediction" : null}</h3>
          </Col>
        </Row>
      {
        gameId === "651f9c4ea5e67037d308e120" ? <NumberPredictionGameHistoryBoard userid={userid} gameId={gameId} gameHistoryid={gameHistoryid}/> : "Not Found"
      }
      </div>
    </Col>
    </Row>
    </>
  );
}

export default GameHistoryBoard;
