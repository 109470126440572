import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getGames } from "../../../reducers/commonReducer";
import SideBaar from "../../../Components/SideBaar";
import { Button } from "react-bootstrap";

const GameTickets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let width = window.innerWidth;

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getGames());
  }, [navigate, dispatch]);
  const {  } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className={`user-table ${width > 500 ? `mt-4 mb-2` : ``}`}>
          <Col md={6}>
            <h3>Games Ticket & Amount</h3>
          </Col>
          <Col md={6} className="text-end d-flex">
            <Button className="mx-1" variant="danger">Sold Ticket ??</Button>
            <Button className="mx-1" variant="success">Unsold Ticket ??</Button>
            <Button className="mx-1" variant="info">Pending Amount ????/-</Button>
          </Col>
        </Row>
        <hr/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Name</th>
                <th>Game Id</th>
                <th>Ticket Sold</th>
                <th>Ticket Price</th>
                <th>Total Amount</th>
                <th>Ticket Commission</th>
                <th>Pending</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1.</td>
                    <td>Rekha</td>
                    <td>345</td>
                    <td><Button className="m-1 py-1 px-2" variant="info" onClick={() => navigate(`/game-tickets-details`)}>2</Button></td>
                    <td>50</td>
                    <td>100</td>
                    <td>20</td>
                    <td>80</td>
                    <td><Button variant="warning">Recieved</Button></td>
                </tr>
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default GameTickets;
