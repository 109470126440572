import React from 'react'
import { Col, Row} from 'react-bootstrap'

export default function DeclaredNumber() {
  return (
            <Col md={6} className='secondary-color'>
                <Row className='text-center'>
                        <h3 className='pt-2'>Declared Number</h3>
                </Row>
                <Row className='mb-2 justify-content-center align-items-center'>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>40</span></div>
                    <div className='declared-num'><span>30</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>40</span></div>
                    <div className='declared-num'><span>30</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>01</span></div>
                    <div className='declared-num'><span>40</span></div>
                    <div className='declared-num'><span>30</span></div>
                </Row>
            </Col>
        )
    }
