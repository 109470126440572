import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Table, Col, Row} from "react-bootstrap";
import Header from "../../Components/Header";
import { addPaymentOptions, getPaymentOptions, updatePaymentOptions } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import PaymentOptionAction from "./PaymentOptionAction";

const PaymentOptions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getPaymentOptions());
  }, [navigate, dispatch]);
  const { paymentOptions } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Payment Gateway</h3>
          </Col>
          <Col md={6}>
            <PaymentOptionAction api={addPaymentOptions} btnTitle={"Add"} btnFloat={"float-right"}/>
          </Col>
        </Row>
        <hr/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr.</th>
                <th>Name</th>
                <th>Label</th>
                <th>Payment Url</th>
                <th>Min</th>
                <th>Max</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!paymentOptions &&
                paymentOptions?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.name}</td>
                      <td>{data?.label}</td>
                      <td>
                      {data?.paymenturl}<br/>
                      {data?.verifyurl && <><b>VerifyUrl</b>: {data?.verifyurl}</>}
                      </td>
                      <td>{data?.min}</td>
                      <td>{data?.max}</td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td><PaymentOptionAction min={data?.min} verifyurl={data?.verifyurl} max={data?.max} api={updatePaymentOptions} id={data?.id} label={data?.label} name={data?.name} paymenturl={data?.paymenturl} status={data?.status}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default PaymentOptions;
