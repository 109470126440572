import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import Header from "../../../Components/Header";
import { getSubAdmin, restRecord, tambolaBooking } from "../../../reducers/commonReducer";
import SideBaar from "../../../Components/SideBaar";
import moment from 'moment';
import AutoFillDate from "../../../AutoFillDate";

const TambolaBooking = () => {
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(moment().format('YYYY-MM-DDT00:00:00'));
  const [endDate, setendDate] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [userName, setUserName] = useState();
  const [gameid, setGameid] = useState();
  const [day, setDay] = useState();
  const [subadmin, setSubadmin] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  let width = window.innerWidth;

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(tambolaBooking({end:endDate, start:startDate,}));
    if(localStorage.getItem("userRole") === "1"){
      dispatch(getSubAdmin());
    }
  }, [dispatch, navigate]);
  const { tambolaBookingList, getSubAdminList, tambolaBookingBlank } = useSelector((state) => state.commonReducer);
  let currentTime = new Date();
  currentTime.setMinutes(currentTime.getMinutes() + 5);

  const handleSubmit = (e) => {
    dispatch(restRecord())
    dispatch(tambolaBooking({
       end:endDate, start:startDate, username:userName, gamename:gameid, day , subadmin
    }))
  }

  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className={`user-table ${width > 500 ? `mt-4 mb-2 ` : ``}`}>
          <Col md={6}>
            <h3>Tambola Booking</h3>
          </Col>
        </Row>
        <hr className="m-0"/>
        <Row className="mb-2 user-table">
          <Col md={2} sm={6} xs={6}>
            <label>
              <b>Start Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <AutoFillDate setendDate={setendDate} setstartDate={setstartDate} day={day} setDay={setDay}/>
          </Col>
          <Col md={1} sm={6} xs={6}>
            <label>
              <b>GameId</b>
            </label>
            <Form.Control
              type="text"
              step="1"
              value={gameid}
              onChange={(e) => setGameid(e.target.value)}
            />
          </Col>
          <Col md={2} sm={12} xs={12}>
            <label>
              <b>User Name</b>
            </label>
            <Form.Control
              type="text"
              placeholder="Enter UserName"
              step="1"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Col>
          <Col md={2}>
            {
               localStorage.getItem("userRole") === "1" && <>
                <label>
                  <b>SubAdmin</b>
                </label>
                <Form.Select value={subadmin} onChange={(e) => setSubadmin(e.target.value)} aria-label="Default select example">
                  <option value="">Select SubAdmin</option>
                  {
                    !!getSubAdminList && getSubAdminList?.map((data, index) => <option key={index} value={data?.id}>{data?.username}</option>)
                  }
                </Form.Select>
               </>
            }
          </Col>
          <Col md={1}>
            <Button className="mt-4 w-50 px-1" variant="success" type="submit" onClick={handleSubmit}>
            <i className="bi bi-search"></i>
            </Button>
            <Button className="mt-4 w-50 px-1" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
            <i className="bi bi-arrow-clockwise"></i>
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>Game Start</th>
                <th>Transaction Time</th>
                <th>Username</th>
                <th>Starts With</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Remaining</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {!!tambolaBookingList &&
                tambolaBookingList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{moment(data?.gamestart).format('DD/MM/YYYY hh:mm A')}</td>
                      <td>{moment(data?.tnsdate).format('DD/MM/YYYY hh:mm A')}</td>
                      <td><b>{`${data?.username} (${data?.name})`}</b></td>
                      <td>{data?.amount}</td>
                      <td>{data?.startswith}</td>
                      <td>{data?.type}</td>
                      <td>{data?.remaining}</td>
                      <td>{data?.comment}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!tambolaBookingBlank && tambolaBookingBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(tambolaBooking({currentPage:currentPage + 1, end:endDate, start:startDate, username:userName, gamename:gameid, day, subadmin}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default TambolaBooking;
