import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getOTP } from '../../reducers/commonReducer';

function Profile(props) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    profileView,
    coins,
    username,
    createdAt,
    phone,
    role,
    winLoss,
    appversion,
    device_id,
    onlineAmount,
    withdraw,
    bonus_coins,
    os,
    offline_add_coins,
    customerIp,
    deviceName,
    prime,
    personName
  } = props;
  const { userOTP } = useSelector((state) => state.commonReducer);
  return (
    <>
    {
        profileView ? 
        <Button className='m-1 py-1 px-2' type="button" variant="primary" onClick={() => {
            handleShow();
            if(phone){
              dispatch(getOTP({phone}));
            }
          }}>
            {profileView ?  <i className="bi bi-eye-fill"></i> : <b>{`${username} (${personName})`}</b>}
          </Button> : <span type="button" className={prime ? 'text-success' : ''} onClick={() => {handleShow();
                if(phone){
                  dispatch(getOTP({phone}));
                }
            }}>
            {profileView ?  <i className="bi bi-eye-fill"></i> : <b>{`${username} (${personName})`}</b>}
        </span>
    }
      
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
        <Modal.Header closeButton className={prime ? "bg-success text-white" : 'bg-info'}>
          <Row className='w-100'>
            <Col md={6}>
              <h4 className={`m-0`}>{username}</h4>
            </Col>
            <Col md={6}>
              <h4 className={`m-0`}>OTP: {!!userOTP && userOTP}</h4>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div className="container profile-sub-details">
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>UserName</b>
                    </div>
                    <div className='col-md-6'>
                      {username}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Role</b>
                    </div>
                    <div className='col-md-6'>
                      {role}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Wallet Balance</b>
                    </div>
                    <div className='col-md-6'>
                      {coins}
                    </div>
                  </div>
                </div>
                {/* <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Email</b>
                    </div>
                    <div className='col-md-6'>
                      {email}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Phone</b>
                    </div>
                    <div className='col-md-6'>
                      {phone}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Register Date</b>
                    </div>
                    <div className='col-md-6'>
                      {moment(createdAt).format('DD/MM/YYYY hh:mm:ss')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Win/Loss</b>
                    </div>
                    <div className='col-md-6'>
                      {winLoss}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>App Version</b>
                    </div>
                    <div className='col-md-6'>
                      {appversion}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>OS</b>
                    </div>
                    <div className='col-md-6'>
                      {os}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Device ID</b>
                    </div>
                    <div className='col-md-6'>
                      {device_id}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Online Amount</b>
                    </div>
                    <div className='col-md-6'>
                      {onlineAmount}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Offline Amount</b>
                    </div>
                    <div className='col-md-6'>
                      {offline_add_coins}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Customer IP</b>
                    </div>
                    <div className='col-md-6'>
                      {customerIp}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>DeviceName</b>
                    </div>
                    <div className='col-md-6'>
                      {deviceName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Withdraw</b>
                    </div>
                    <div className='col-md-6'>
                      {withdraw}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Bonus Coins</b>
                    </div>
                    <div className='col-md-6'>
                      {bonus_coins}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' className="btn" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Profile;
