import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import reward from '../Web/img/reward/reward.png'
import rewardlist from '../Web/img/reward/rewardlist.png'
import quickfive from '../Web/img/reward/quickfive.png'
import quickseven from '../Web/img/reward/quickseven.png'
import fullhouse from '../Web/img/reward/fullhouse.png'
import bottomline from '../Web/img/reward/bottomline.png'
import middleline from '../Web/img/reward/middleline.png'
import topline from '../Web/img/reward/topline.png'
import setcorner from '../Web/img/reward/setcorner.png'
import forcorner from '../Web/img/reward/forcorner.png'

function GameInformation() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <i className="bi bi-info-circle-fill" onClick={handleShow}></i>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='pt-0 pb-0 bg-info'>
          <Modal.Title>How to play</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 pb-0'>
            <p>Welcome to TAMBOLA HOUSE, the ultimate online live streaming platform for all Housie enthusiasts! Get ready to dive into the world of thrilling gameplay, where you can enjoy the excitement of the live draw and have a chance to win significant cash prizes up to the defined limits.</p>
            <p className='mb-0'><b>How to Play <i className="bi bi-controller"></i></b></p>
            <ul>
              <li>Start by purchasing or booking your desired tickets from the 'Buy' option. You can choose to buy/book tickets up to the specified limit displayed on the ticket dashboard.</li>
              <li>Ticket sales/bookings will close 5 minutes before the scheduled draw time.</li>
              <li>Once the live draw begins, click on the 'Live' option to immerse yourself in the live game experience.</li>
              <li>An exceptional feature of this game is that your purchased/booked tickets remain valid even if you go offline. During the live game, the drawn numbers will be automatically marked on your tickets, and any winning prizes will be credited directly to your wallet. Just ensure you've bought/booked your tickets before the game start.</li>
              <li>Whether you're familiar with Tambola rules or not, you can enjoy this live game. The system automatically marks the numbers on your tickets and credits your wallet with winnings. TAMBOLA HOUSE ensures fairness through its live show format.</li>
            </ul>
            <p className='mb-0'><b>Prizes <i className="bi bi-trophy-fill"></i></b></p>
            <p className='mb-0'>To discover the potential rewards, you can check out the 'Rewards' option on our platform.</p>
            <img className='w-100' src={reward} alt="reward"/>
            <img className='w-100' src={rewardlist} alt="reward"/>
            <p className='mt-2 mb-0 text-center'>
              <b>Winning Prizes</b>
            </p>
            <hr className='mt-0'/>
            <p className='mb-0'><b>Quick 5</b>: The players who have all five numbers marked in any one of the boxes on their ticket will win. For example, achieving this in a single box on the ticket will lead to a win.</p>
            <img className='w-100' src={quickfive} alt="reward"/>
            <p className='mb-0'><b>Quick 7</b>: If a player's ticket has all seven numbers marked in any one of the boxes, they secure a win. The first to mark all seven numbers in a single box wins.</p>
            <img className='w-100' src={quickseven} alt="reward"/>
            <p className='mb-0'><b>4 Corner</b>: A win in this category is for the players whose marked numbers align with the top and bottom two corners of a single box in any part of their ticket.</p>
            <img className='w-100' src={forcorner} alt="reward"/>
            <p className='mb-0'><b>Set Corner</b>: Players win this category if the numbers in their ticket's top box's first two corners match with the bottom box's last two corners in any part of the ticket.</p>
            <img className='w-100' src={setcorner} alt="reward"/>
            <p className='mb-0'><b>Top Line</b>: To win in this category, a player's marked numbers must match all the numbers in any one of the top lines across the ticket's boxes.</p>
            <img className='w-100' src={topline} alt="reward"/>
            <p className='mb-0'><b>Middle Line</b>: Achieving a win in this category requires matching all the numbers in any one of the middle lines across the ticket's boxes.</p>
            <img className='w-100' src={middleline} alt="reward"/>
            <p className='mb-0'><b>Bottom Line</b>: For this category, players need to mark all the numbers in any one of the bottom lines across the ticket's boxes to secure a win.</p>
            <img className='w-100' src={bottomline} alt="reward"/>
            <p className='mb-0'><b>House Full & Second House Full</b>: The ultimate win comes to players who mark all the numbers—top, middle, and bottom lines—in a single box on their ticket. This applies to both the main "House Full" and "Second House Full" categories.</p>
            <img className='w-100' src={fullhouse} alt="reward"/>
            <p>Join us at TAMBOLA HOUSE and experience the excitement of live gameplay, effortless ticket management, and the chance to win attractive cash prizes. It's a game of fairness and entertainment you won't want to miss!</p>
        </Modal.Body>
        <Modal.Footer className='pt-0 pb-0'>
          <Button variant="danger" className='w-100' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GameInformation;