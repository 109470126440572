import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getKYC } from "../../reducers/commonReducer";

const schema = yup.object().shape({
});

function KycAction(props) {
  const dispatch = useDispatch();
  const {id, status, api} = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={status === 0 ? 'info' : status === 1 ? 'danger' : 'success'} onClick={handleShow}>
      <i className="bi bi-pen-fill"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>Update Payment Status</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(api(values));
            resetForm({ values: "" });
            dispatch(getKYC())
            setShow(false)
          }}
          initialValues={{
            status, id
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Select KYC Status</Form.Label>
                      <Form.Control
                              as="select"
                              onChange={(e) => setFieldValue('status', +e.target.value)}
                              name="status"
                              className="form-control"
                              value={values.status}
                              isInvalid={!!errors.status}
                            >
                              <option value="">Select KYC Status</option>
                              <option value={0}>Pending</option>
                              <option value={1}>Failed</option>
                              <option value={2}>Success</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default KycAction;
