import React, { useEffect } from 'react';
import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionResponse } from '../../reducers/commonReducer';
import { Button } from 'react-bootstrap';
import moment from 'moment';

export const SubscriptionRespose = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(subscriptionResponse({transaction_id:id}))
  },[])
  const {subscriptionResponseDetails, subscriptionUser} = useSelector((state) => state.commonReducer);
  return (
    <div className="row response-bg">
        <div className='col-md-3'></div>
        <div className='col-md-6 response-payment'>
          <h1 className={subscriptionResponseDetails?.txn_status == 0 && "text-secondary" || subscriptionResponseDetails?.txn_status == 1 && "text-danger" || subscriptionResponseDetails?.txn_status == 2 && "text-success"}><b>{subscriptionResponseDetails?.message}</b></h1>
          <b className='text-white'>Hi, Name:- {subscriptionUser?.name},  Mobile:- {subscriptionUser?.phone},  Email:- {subscriptionUser?.email ? subscriptionUser?.email : ''}, Website:- {subscriptionUser?.domain}.tambola.one, Plan:-{subscriptionUser?.plan?.name}, Paid:- {subscriptionUser?.amount}.</b>
          <div>
          {/* <Button className='m-1' variant='success' onClick={() => window.location.replace(`https://api.whatsapp.com/send?phone=+919588377039&text=Hi, my name is ${subscriptionUser?.name}. You can reach me at ${subscriptionUser?.phone} or via email at ${subscriptionUser?.email ? subscriptionUser?.email : ''}. My domain is ${subscriptionUser?.domain}.tambola.one. My ${subscriptionUser?.plan?.name} plan is currently active until ${moment(subscriptionUser?.expire).format('DD/MM/YYYY hh:mm:ss')} (${subscriptionUser?.plan?.days}days), and the total paid amount is ${subscriptionUser?.amount}. Thank you!&source=&data=&app_absent=`)}><i className="bi bi-whatsapp"></i> Click to Whatsapp</Button> */}
          <Button className='m-1' variant='success' onClick={() => window.location.replace(`https://api.whatsapp.com/send?phone=+919588377039&text=Hi, Name:- ${subscriptionUser?.name},  Mobile:- ${subscriptionUser?.phone}, Email:- ${subscriptionUser?.email ? subscriptionUser?.email : ''}, Website:- ${subscriptionUser?.domain}.tambola.one, Plan:-${subscriptionUser?.plan?.name}, Paid:- ${subscriptionUser?.amount}, Payment Status:- ${((subscriptionResponseDetails?.txn_status === 0) && "Pending") || ((subscriptionResponseDetails?.txn_status === 1) && "Reject") || ((subscriptionResponseDetails?.txn_status === 2) && "Success")}, Theme:- ${subscriptionUser?.theme ? subscriptionUser?.theme : ''}&source=&data=&app_absent=`)}><i className="bi bi-whatsapp"></i> Click to Whatsapp</Button>
          <Button className='m-1' variant='warning' onClick={() => navigate(`/`)}><i className="bi bi-house-check-fill"></i> Go to Home</Button>
          </div>
        </div>
        <div className='col-md-3'></div>
    </div>
  );
};

