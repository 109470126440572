import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Header from './Header';
import GameCard from './GameCard';

export default function GameList() {
  return (
    <div className='base-color min-height'>
        <Container className='pb-3'>
            <Header/>  
            <Row className='justify-content-center mb-3 side-spacing'>
              <GameCard ticketnum={"001"} username={"Rekha"}/>
            </Row>
            <Row className='justify-content-center mb-3 side-spacing'>
              <GameCard ticketnum={"001"} username={"Rekha"}/>
            </Row>
            <Row className='justify-content-center mb-3 side-spacing'>
              <GameCard ticketnum={"001"} username={"Rekha"}/>
            </Row>
            <Row className='justify-content-center mb-3 side-spacing'>
              <GameCard ticketnum={"001"} username={"Rekha"}/>
            </Row>
            <Row className='justify-content-center mb-3 side-spacing'>
              <GameCard ticketnum={"001"} username={"Rekha"}/>
            </Row>
            <Row className='justify-content-center mb-3 side-spacing'>
              <GameCard ticketnum={"001"} username={"Rekha"}/>
            </Row>
        </Container>
    </div>
  )
}
