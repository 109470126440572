import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Table, Col, Row} from "react-bootstrap";
import Header from "../../Components/Header";
import { gameUpdate, getGames } from "../../reducers/commonReducer";
import GameAction from "./GameAction";
import SideBaar from "../../Components/SideBaar";

const GameList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getGames());
  }, [navigate, dispatch]);
  const { getGamesList } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Games</h3>
          </Col>
          {/* <Col md={6}>
            <GameAction btnTitle={"Add Game"} btnFloat={"float-right"}/>
          </Col> */}
        </Row>
        <hr/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Minimum Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!getGamesList &&
                getGamesList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data?.id}</td>
                      <td>{data?.name}</td>
                      <td>{data?.minimumamount}</td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td><GameAction api={gameUpdate} id={data?.id} minimumamount={data?.minimumamount} status={data?.status}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default GameList;
