import React from 'react'

const Cards = (props) => { 
  
  return (
    <> 
        <div className={`card ${props.background} order-card p-3 text-white min-height-60 m-3`}>
            <div className="card-block">
              <h6 className="m-b-20">{props.title}</h6> 
              <div className='row'>
                <div className='col-6'>
                  <i className={`bi ${props.icon} fs-1`}></i> 
                </div> 
                <div className='col-6 text-end'>
                  <span className='fs-2'> <b>{props.subtitle}</b></span>
                </div>
              </div> 
              <div className='row'>
                <div className='col-6'>
                  <p>{props.completedOrders}</p>
                </div> 
                <div className='col-6 text-end'> 
                <p><b>{props.completedOrdersNumber}</b></p> 
                </div>
              </div>
            </div>
        </div>
    </>
  )
}

export default Cards
