import React, { useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import Cards from "../../Components/Card";
import { getDashboard } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import { Col, Row } from "react-bootstrap";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getDashboard());
  }, [navigate, dispatch]);
  const { getDashboardData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container content mt-3">
        <div className="row">
          <div className="col-md-4">
            <NavLink>
              <Cards
                title="Total Games"
                icon="bi-dice-2-fill"
                background="bg-c-green"
                subtitle={getDashboardData?.games}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink>
              <Cards
                title="Total Customers"
                icon="bi-people-fill"
                background="bg-c-blue"
                subtitle={getDashboardData?.customers}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink>
              <Cards
                title="Total Blocked"
                icon="bi-person-fill-slash" 
                background="bg-c-green"
                subtitle={getDashboardData?.blocked}
              />
            </NavLink>
          </div>
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default Dashboard;
