import React, { useEffect } from "react";
import "./app.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./DashboardPanel/LoginPage";
import { ToastContainer } from "react-toastify";
import NotFoundPage from "./Components/NotFoundPage";
import Dashboard from "./DashboardPanel/Dashboard/Dashboard";
import GameList from "./DashboardPanel/GamesManage/GameList.js";
import AmountList from "./DashboardPanel/AmountManage/AmountList.js";
import UserPaymentList from "./DashboardPanel/UserPaymentManage/UserPaymentList.js";
import WithdrawalList from "./DashboardPanel/WithdrawalManage/WithdrawalList.js";
import TransactionsList from "./DashboardPanel/TransactionsManage/TransactionsList.js";
import LockedDeviceList from "./DashboardPanel/LockedDevicesManage/LockedDeviceList.js";
import InactiveUserList from "./DashboardPanel/InactiveUsersManage/InactiveUserList.js";
import GSTList from "./DashboardPanel/GSTManage/GSTList.js";
import TDSList from "./DashboardPanel/TDSManage/TDSList.js";
import KycList from "./DashboardPanel/KycManage/KycList.js";
import SettingList from "./DashboardPanel/SettingsManage/SettingList.js";
import Profile from "./DashboardPanel/ProfileManage/Profile.js";
import UserList from "./DashboardPanel/UsersManage/UserList.js";
import Transactions from "./DashboardPanel/UsersManage/Transactions.js";
import TambolaFutureGamesList from "./DashboardPanel/GamesManage/Tambola/TambolaFutureGamesList.js";
import TambolaGameView from "./DashboardPanel/GamesManage/Tambola/TambolaGameView.js";
import TambolaBooking from "./DashboardPanel/GamesManage/Tambola/TambolaBooking.js";
import NumberPredictionBoard from "./DashboardPanel/GamesManage/NumberPrediction/NumberPredictionBoard.js";
import GameHistory from "./DashboardPanel/GamesManage/GameHistory.js";
import UserGameHistory from "./DashboardPanel/GamesManage/UserGameHistory.js";
import GameTransactions from "./DashboardPanel/GamesManage/GameTransactions.js";
import GameHistoryBoard from "./DashboardPanel/GamesManage/GameHistoryBoard.js";
import TambolaTicketBookingBoard from "./DashboardPanel/GamesManage/Tambola/TambolaTicketBookingBoard.js";
import SubAdmins from "./DashboardPanel/UsersManage/SubAdmins.js";
import GameTickets from "./DashboardPanel/GamesManage/Tambola/GameTickets.js";
import GameTicketsDetails from "./DashboardPanel/GamesManage/Tambola/GameTicketsDetails.js";
import Subscription from "./DashboardPanel/Subscription/Subscription.js";
import SubscriptionDetailsAction from "./DashboardPanel/Subscription/SubscriptionDetailsAction.js";
import { SubscriptionRespose } from "./DashboardPanel/Subscription/SubscriptionRespose.js";
import SubscriptionPaymentList from "./DashboardPanel/Subscription/SubscriptionPaymentList.js";
import { UserPayment } from "./DashboardPanel/Payments/UserPayment.js";
import { PaymentResponse } from "./DashboardPanel/Payments/PaymentResponse.js";
import Tickets from "./Web/Tickets.js"
import GameBoard from "./Web/GameBoard.js"
import AgentGameList from "./Web/GameList.js"
import Agents from "./Web/Agents.js"
import { applyBaseColor, applySecondaryColor } from "./const.js";
import CompletedGames from "./Web/CompletedGames.js";
import PaymentOptions from "./DashboardPanel/PaymentOption/PaymentOptions.js";
import PayoutList from "./DashboardPanel/Payout/PayoutList.js";
import {socket} from "../src/helpers/socket.js"
import MyComponent from "./Web/Chat.js";
import { useDispatch } from "react-redux";
import { getProfile } from "./reducers/commonReducer.js";
import ColorGameHistory from "./DashboardPanel/GamesManage/ColorGame/ColorGameHistory.js";
import ColorCurrentGame from "./DashboardPanel/GamesManage/ColorGame/ColorCurrentGame.js";
import RedBlackGameHistory from "./DashboardPanel/GamesManage/RedBlack/RedBlackGameHistory.js";
import RedBlackCurrentGame from "./DashboardPanel/GamesManage/RedBlack/RedBlackCurrentGame.js";
import QuestionList from "./DashboardPanel/Questions/QuestioList.js";
import TicketList from "./DashboardPanel/Tickets/TicketList.js";


function App() {
  const siteType = process.env.REACT_APP_SITE_TYPE;
  const dispatch = useDispatch();
  // const [messages, setMessages] = useState([]);
  // const [inputMessage, setInputMessage] = useState('');
  
  useEffect(() => {
    applyBaseColor("#00203f");
    applySecondaryColor("#adf0d1")
    dispatch(getProfile());
    // socket.connect();
    // socket.on('gameLiveUsers', (message) => {
    //   setMessages((prevMessages) => [...prevMessages, message]);
    // });
    // return () => {
    //   socket.disconnect();
    // };
  },[])

  return (
    <>
      <Routes>
        {/* Subscription */}
        <Route path="/subscription" element={<SubscriptionDetailsAction/>} />
        <Route path={`/subscripiton/response/:id`} element={<SubscriptionRespose/>} />
        <Route path={`/subscripiton/payments`} element={<SubscriptionPaymentList/>} />
        {/* agent panel */}
        <Route path="/chat" element={<MyComponent />} />
        <Route path="/game" element={<GameBoard />} />
        <Route path="/agent-game-list" element={<AgentGameList />} />
        <Route path="/agent-completed-game" element={<CompletedGames />} />
        <Route path="/agents" element={<Agents />} />
        {/* Payment */}
        <Route path="/pay/:userid/:amount" element={<UserPayment/>}/>
        <Route exact path="/response" component={<PaymentResponse/>} />
        {/* All */}
        <Route path="/" element={
            siteType === "admin" ? (
              <LoginPage />
            ) : siteType === "sales" ? (
              <Subscription />
            ) : siteType === "agent" ? (
              <Tickets/>
            ) : (
             null
            )
          } />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/game-list" element={<GameList />} />
        <Route path="/questions" element={<QuestionList />} />
        <Route path="/tickets" element={<TicketList />} />
        <Route path="/payment-option" element={<PaymentOptions />} />
        <Route path="/payout" element={<PayoutList />} />
        <Route path="/red-black-game-history" element={<RedBlackGameHistory />} />
        <Route path="/red-black-current-game" element={<RedBlackCurrentGame />} />
        <Route path="/color-game-history" element={<ColorGameHistory />} />
        <Route path="/color-current-game" element={<ColorCurrentGame />} />
        <Route path="/game-history" element={<GameHistory />} />
        <Route path="/game-history-board" element={<GameHistoryBoard />} />
        <Route path="/game-transactions" element={<GameTransactions />} />
        <Route path="/user-game-history" element={<UserGameHistory />} />
        <Route path="/tambola-future-games" element={<TambolaFutureGamesList />} />
        <Route path="/tambola-ticket-booking" element={<TambolaTicketBookingBoard />} />
        <Route path="/tambola" element={<TambolaGameView />} />
        <Route path="/game-tickets" element={<GameTickets />} />
        <Route path="/game-tickets-details" element={<GameTicketsDetails />} />
        <Route path="/tambola-booking" element={<TambolaBooking />} />
        <Route path="/numberprediction" element={<NumberPredictionBoard />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/sub-admin" element={<SubAdmins />} />
        <Route path="/amount-list" element={<AmountList />} />
        <Route path="/user-payment" element={<UserPaymentList />} />
        <Route path="/withdrawal" element={<WithdrawalList />} />
        <Route path="/transactions" element={<TransactionsList />} />
        <Route path="/user-transaction" element={<Transactions />} />
        <Route path="/locked-devices" element={<LockedDeviceList />} />
        <Route path="/inactive" element={<InactiveUserList />} />
        <Route path="/gst" element={<GSTList />} />
        <Route path="/tds" element={<TDSList />} />
        <Route path="/kyc" element={<KycList />} />
        <Route path="/settings" element={<SettingList />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}
export default App;
