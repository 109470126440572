import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Table} from 'react-bootstrap';
import { numberPdGameHistory } from '../../../reducers/commonReducer';

function NumberPredictionGameHistoryBoard({userid, gameId, gameHistoryid}) {
  const apiparms = {userid, gameId, gameHistoryid}
  const dispatch = useDispatch();

  useEffect(() => {
     dispatch(numberPdGameHistory(apiparms))
  }, [dispatch , userid, gameHistoryid]);
  
  const { numberPdGameBettings } = useSelector((state) => state.commonReducer);
  return (
    <>
    <Table striped bordered hover size="sm" className="overflow-scroll">
        <thead>
            <tr>
                <th>Sr No.</th>
                <th>Amount</th>
                <th>Numbers</th>
                <th>Winning</th>
            </tr>
        </thead>
        <tbody>
        {
            !!numberPdGameBettings && numberPdGameBettings?.map((data, index) => {
                return(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.amount}</td>
                            <td>{data?.numbers?.map((item) => `${item}, `)}</td>
                            <td>{data?.win_status === true && data?.winning}</td>
                        </tr>
                )
            })
        }
        </tbody>
    </Table>
    </>
  );
}

export default NumberPredictionGameHistoryBoard;
