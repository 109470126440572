import React from 'react';
import { Col, Row } from 'react-bootstrap';

const NumberBoard = (props) => {
  const { crossnumbers } = props;
  const numbers = [];
  for (let i = 1; i <= 80; i++) {
    const paddedNumber = i.toString().padStart(2, '0');
    const numbersArray = crossnumbers?.number?.split(',');
    const decalerdArray = !!numbersArray && numbersArray?.map(Number);
    const crossbtn = (!!decalerdArray && decalerdArray?.includes(i)) ? 'cross-btn' : '';
    numbers?.push(
      <Col md={1} className={`board-num ${crossbtn}`} key={i}>
        {paddedNumber}
      </Col>,
    );
  }
  return (
    <>
      <Row className="tambola-row-mrgn">{numbers}</Row>
    </>
  );
};

export default NumberBoard;
