import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import './styles.css';

const TambolaBoard = (props) => {
  const { crossnumbers } = props;
  const numbers = [];
  for (let i = 1; i <= 90; i++) {
    const paddedNumber = i.toString().padStart(2, '0');
    const crossbtn =
      !!crossnumbers?.numbers && crossnumbers?.numbers.includes(i) ? 'cross-btn' : '';
    numbers.push(
      <Col md={1} className={`board-num ${crossbtn}`} key={i}>
        {paddedNumber}
      </Col>,
    );
  }
  return (
    <>
      <Row className="tambola-row-mrgn">{numbers}</Row>
    </>
  );
};

export default TambolaBoard;
