import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getPaymentOptions } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  label : yup.string().required(),
  name : yup.string().required(),
  paymenturl : yup.string().required(),
  min : yup.number().required(),
  max : yup.number().required(),
  status: yup.boolean().required("Please Select Status."),
});

function PaymentOptionAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {btnTitle, btnFloat, id, label, name, paymenturl, status, api, verifyurl, min, max} = props;
  return (
    <>
      <Button variant="success" onClick={handleShow} className={btnFloat}>
       {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : "Update"}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiresult = await dispatch(api(values));
            if(apiresult?.payload?.status){
              resetForm({ values: "" });
              dispatch(getPaymentOptions())
              setShow(false)
            }
          }}
          initialValues={{
            id, label, name, paymenturl, status, min, max, verifyurl
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Enter Label</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Label"
                      name="label"
                      value={values.label}
                      onChange={handleChange}
                      isInvalid={!!errors.label}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.label}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Enter Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={12}>
                    <Form.Group >
                      <Form.Label>Enter Verify Url</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Verify Url"
                        name="verifyurl"
                        value={values.verifyurl}
                        onChange={handleChange}
                        isInvalid={!!errors.verifyurl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.verifyurl}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col md={6}>
                    <Form.Group >
                      <Form.Label>Enter Paymenturl</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Paymenturl"
                        name="paymenturl"
                        value={values.paymenturl}
                        onChange={handleChange}
                        isInvalid={!!errors.paymenturl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.paymenturl}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group >
                      <Form.Label>Select Status</Form.Label>
                        <Form.Control
                                as="select" 
                                onChange={(e) => setFieldValue('status', (/true/).test(e.target.value))}
                                name="status"
                                className="form-control"
                                value={values.status}
                                isInvalid={!!errors.status}
                              >
                                <option value="">Select Status</option>
                                <option value={true}>Active</option>
                                <option value={false}>InActive</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.status}
                              </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3 mt-3">
                  <Col md={6}>
                      <Form.Group >
                        <Form.Label>Min</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Min"
                          name="min"
                          value={values.min}
                          onChange={handleChange}
                          isInvalid={!!errors.min}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.min}
                        </Form.Control.Feedback>
                      </Form.Group>
                  </Col>
                  <Col md={6}>
                      <Form.Group >
                        <Form.Label>Max</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Max"
                          name="max"
                          value={values.max}
                          onChange={handleChange}
                          isInvalid={!!errors.max}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.max}
                        </Form.Control.Feedback>
                      </Form.Group>
                  </Col>
                </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default PaymentOptionAction;
